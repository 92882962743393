import React, { useEffect, useState } from "react";

function App() {
  // Estados para armazenar email, senha, token e dados retornados
  const [email, setEmail] = useState("john@example.com");
  const [password, setPassword] = useState("john@example.com");
  const [bearerToken, setBearerToken] = useState("");
  const [dados, setDados] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  // Endpoints
  const LOGIN_URL = "https://programandodozero.com.br/api_new/public/api/login";
  const GET_URL = "https://programandodozero.com.br/api_new/public/api/dadosdoenca/datalite/2024-11-24/1/evi";

  // Função para efetuar o login
  const handleLogin = async (e) => {
    e.preventDefault(); // Evita recarregar a página no submit do formulário
    setError("");
    setLoading(true);

    try {
      // 1) Fazendo a requisição de LOGIN
      const response = await fetch(LOGIN_URL, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Accept": "application/json",           
        },
        body: JSON.stringify({
          email: email,
          password: password,
        }),
      });

      // Verifica se a resposta não foi bem sucedida (HTTP >= 400)
      if (!response.ok) {
        throw new Error("Falha ao fazer login. Verifique suas credenciais.");
      }

      const data = await response.json();
      //console.log("Resposta do login:", data);

      if (!data.access_token) {
        throw new Error("Token não retornado. Verifique o endpoint ou as credenciais.");
      }

      // 2) Armazena o token Bearer
      const token = data.token_type + " " + data.access_token;
      setBearerToken(token);


    } catch (err) {
      setError(err.message);
      console.error("Ocorreu um erro:", err);
    } finally {
      setLoading(false);
    }
  };

  const handleDados = async (e) => {
    e.preventDefault(); // Evita recarregar a página no submit do formulário
    setError("");
    setLoading(true);

    try {
      // 3) Faz a requisição GET passando o token
      const getResponse = await fetch(GET_URL, {
        method: "GET",
        headers: {
          "Accept": "application/json", 
          "Accept-Encoding": "application/json",
          Authorization: bearerToken,
        },
      });

      if (!getResponse.ok) {
        throw new Error("Falha ao obter dados (GET).");
      }

      const getData = await getResponse.json();
      //console.log("Dados obtidos do GET:", getData);
      setDados(getData); // Armazena no state para exibir na tabela

    } catch (err) {
      setError(err.message);
      console.error("Ocorreu um erro:", err);
    } finally {
      setLoading(false);
    }
  };

  /*useEffect(() => {
    console.log(dados);
  }, [dados]);*/

  return (
    <div style={{ margin: "20px" }}>
      <h1>Exemplo de Login e Consulta (React)</h1>

      {/* Formulário de Login */}
      <form onSubmit={handleLogin} style={{ marginBottom: "20px" }}>
        <div>
          <label>Email:</label><br />
          <input
            type="text"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Digite seu email"
            required
          />
        </div>
        <div style={{ marginTop: "10px" }}>
          <label>Senha:</label><br />
          <input
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            placeholder="Digite sua senha"
            required
          />
        </div>
        <div style={{ marginTop: "10px" }}>
          <button type="submit" disabled={loading}>
            {loading ? "Carregando..." : "Login e Buscar Dados"}
          </button>
        </div>
      </form>

      {/* Botão para buscar dados (GET) */}
      <button onClick={handleDados} disabled={loading}>{loading ? "Carregando Dados..." : "Buscar Dados"}</button>
        

      {/* Exibe erro (se houver) */}
      {error && <p style={{ color: "red" }}>Erro: {error}</p>}

      {/* Exibe token retornado (opcional) */}
      {bearerToken && (
        <div style={{ marginBottom: "20px" }}>
          <strong>Bearer Token:</strong> {bearerToken}
        </div>
      )}

      {/* Exibe os dados em tabela */}
      {dados.length > 0 && (
        <table border="1" cellPadding="5" cellSpacing="0">
          <thead>
            {/* Gerar cabeçalho com base nas chaves do primeiro objeto */}
            <tr>
              {Object.keys(dados[0]).map((key) => (
                <th key={key}>{key}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {dados.map((item, index) => (
              <tr key={index}>
                {Object.keys(item).map((key) => (
                  <td key={key}>{item[key]}</td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      )}

      {/* Caso nenhum dado tenha sido carregado e não esteja carregando */}
      {!loading && dados.length === 0 && (
        <p>Nenhum dado carregado ainda. Faça login para buscar os dados.</p>
      )}
    </div>
  );
}

export default App;
